import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import { getMonthDateYear } from 'helpers/getMonthDateYear';
import { CreatorInArticle } from 'apiFolder/directus-main';

import defaultHero from 'images/generic-article-image.jpeg';

import ImageContainer from 'components/common/image/ImageContainer';

interface ITabArticleWithCreators {
	img: string | null;
	writtenBy: CreatorInArticle[];
	title: string;
	link: string;
	lastReviewed: string;
}

const TabArticleWithCreators: React.FC<ITabArticleWithCreators> = ({ img, title, writtenBy, lastReviewed, link }) => {
	return (
		<Wrapper>
			<Link to={link}>
				<MainImage>
					<ImageContainer
						src={img ? `${img}?key=large` : defaultHero}
						alt={title}
						thumb={`${img!}?key=small`}
					/>
					<InfoBlock>
						<Title>{title}</Title>
						{lastReviewed && <Text>Last medically reviewed {getMonthDateYear(lastReviewed)}</Text>}
					</InfoBlock>
				</MainImage>
			</Link>
			{writtenBy.length && (
				<CreatorsInfo>
					<CreatorImgs>
						{writtenBy.map((creator, index) => (
							<ImageBlock key={`${creator.personId}3*${index}`}>
								<ImageContainer
									src={`${creator.profilePicture!}?key=small`}
									alt={creator.name}
									thumb={`${creator.profilePicture!}?key=small`}
								/>
							</ImageBlock>
						))}
					</CreatorImgs>
					<CreatorNames>
						<InfoText>Written by</InfoText>
						<Name>
							<Link to={writtenBy[0].slug}>{writtenBy[0].name}</Link>
						</Name>
						<InfoText>{writtenBy[0].medicalTitle}</InfoText>
						<InfoText>{writtenBy[0].education[0].university}</InfoText>
					</CreatorNames>
				</CreatorsInfo>
			)}
		</Wrapper>
	);
};

const Wrapper = styled.div`
	flex: 0 0 33.3%;
	padding: 0 15px;
	margin-bottom: 40px;
	cursor: pointer;
	@media (max-width: 1023px) {
		flex: 0 0 50%;
	}
	@media (max-width: 600px) {
		flex: 0 0 100%;
	}
`;
const MainImage = styled.div`
	position: relative;
	width: 100%;
	height: 240px;
	border-radius: 6px;
	overflow: hidden;
	box-shadow: inset 0px 0px 60px -38px #0f0e15a1, 0px 0px 60px -38px #0f0e15a1;
	& img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		transition: all 0.3s ease;
	}

	&:hover {
		& img {
			transform: scale(1.1);
		}
	}
`;
const ImageBlock = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	& img {
		width: 30px;
		height: 30px;
		border-radius: 50%;
		box-shadow: inset 0px 0px 15px -5px #0f0e15a1;
	}
`;
const InfoBlock = styled.div`
	position: absolute;
	width: 100%;
	height: 100px;
	bottom: 0;
	left: 0;
	padding: 15px;
	background: #0f0e15a1;
	border-radius: 6px;
	overflow: hidden;

	display: flex;
	flex-direction: column;
`;
const Title = styled.h3`
	font-family: 'SharpGrotesk-MediumNo21', sans-serif;
	flex: 1 0 auto;
	font-weight: 600;
	font-size: 16px;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2; /* number of lines to show */
	line-clamp: 2;
	-webkit-box-orient: vertical;
	color: #ffffff;
`;
const Text = styled.p`
	font-weight: 300;
	font-size: 12px;
	line-height: 1.5;
	overflow: hidden;
	text-overflow: ellipsis;
	color: #ffffff;
	letter-spacing: 1.1px;
	margin: 6px 0 0px;
`;
const CreatorsInfo = styled.div`
	display: flex;
	margin: 10px 0;
`;
const CreatorImgs = styled.div`
	display: flex;
	margin-right: 6px;
	& img {
		max-width: 30px;
	}
`;

const CreatorNames = styled.div`
	display: flex;
	overflow: hidden;
	text-overflow: ellipsis;
	flex-wrap: wrap;
	align-items: center;
	white-space: nowrap;
	font-weight: 400;
	font-size: 12px;

	& > span:last-child {
		flex: 0 0 100%;
		width: 100%;
	}
`;

const InfoText = styled.span`
	margin-right: 6px;
`;

const Name = styled.div`
	margin-right: 6px;

	& a {
		color: #424bec;
		transition: all 0.3s ease;

		&:hover {
			color: #7c7c7c;
			text-decoration: none;
		}
	}
`;

export default TabArticleWithCreators;
