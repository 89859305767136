import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import HeaderInner from './HeaderInner';

interface IBookingHeader {
	isBurgerOpen: boolean;
	toggleBurgerMenu: () => void;
}

const HeaderComponent: React.FC<IBookingHeader> = ({ isBurgerOpen, toggleBurgerMenu }) => {
	return (
		<Header>
			<HeaderInner isBurgerOpen={isBurgerOpen} toggleBurgerMenu={toggleBurgerMenu} />
			{isBurgerOpen && (
				<Menu>
					<GreyBlock onClick={toggleBurgerMenu} />
					<WhiteBlock>
						<BurgerMenuNav>
							<BurgerMenuNavItem>
								<Link to={'/near-me'}>Near Me</Link>
							</BurgerMenuNavItem>
							<BurgerMenuNavItem>
								<Link to={'/patient-resources'}>Patient Resources</Link>
							</BurgerMenuNavItem>
							<BurgerMenuNavItem>
								<Link to={'/about'}>About Us</Link>
							</BurgerMenuNavItem>
							<BurgerMenuNavItem>
								<Link to={'/contact-us'}>Contact Us</Link>
							</BurgerMenuNavItem>
						</BurgerMenuNav>
						<TextArea>
							<a href='https://bookphysio.com/' target='_blank' rel='noopener noreferrer'>
								BookPhysio.com
							</a>
							is Australia’s #1 physiotherapy booking site. We aim to help everyday Australian’s access
							reliable, evidence-based health information and suitable treatment options via our booking
							engine.
						</TextArea>
					</WhiteBlock>
				</Menu>
			)}
		</Header>
	);
};

const Header = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	background-color: ${({ theme }) => theme.palette.white};
	z-index: 110;
`;

const Menu = styled.div``;

const GreyBlock = styled.div`
	position: fixed;
	left: 0;
	top: 0px;
	width: 100vw;
	height: 100vh;
	background-color: transparent;
	opacity: 0.4;
	overflow-y: auto;
	overscroll-behavior-y: contain;
	z-index: 120;

	&:after {
		content: "''";
		position: absolute;
		left: 0;
		top: 79px;
		width: 100vw;
		height: calc(100vh - 79px);
		background-color: #000;
		opacity: 0.4;
		@media (max-width: 767px) {
			top: 56px;
			height: calc(100vh - 56px);
		}
	}
`;
const WhiteBlock = styled.div`
	background-color: #fff;
	position: fixed;
	left: 0;
	top: 80px;
	width: 30vw;
	height: calc(100vh - 80px);
	padding: 31px 25px 31px 17px;
	transition: width 1s cubic-bezier(0, 0, 1, 1) 300ms;
	z-index: 130;
	@media (max-width: 767px) {
		top: 57px;
		height: calc(100vh - 57px);
		width: 58vw;
		overflow-y: auto;
	}
	@media (max-width: 350px) {
		width: 85vw;
	}
`;
const BurgerMenuNav = styled.ul`
	list-style: none;
	display: flex;
	flex-direction: column;
	width: 100%;
	padding-bottom: 10px;
	border-bottom: 1px solid #e0e0df;
	margin-bottom: 18px;
	text-align: left;
`;
const BurgerMenuNavItem = styled.li`
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 22px;
	margin-bottom: 41px;
	color: #152231;
	a {
		text-decoration: none;
		color: #152231;
	}
`;
const TextArea = styled.p`
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 21px;
	color: #748095;

	& > a {
		color: #424bec;
		margin: 0;
		padding: 0 5px 0 0;
	}
`;

export default HeaderComponent;
