import React from 'react';
import styled from 'styled-components';

const PaddingTopContainer: React.FC<{ isBurgerOpen: boolean; children: React.ReactNode }> = ({
	isBurgerOpen,
	children,
}) => {
	return <MainContentWrapper isBurgerOpen={isBurgerOpen}>{children}</MainContentWrapper>;
};

const MainContentWrapper = styled.div<{ isBurgerOpen: boolean }>`
	position: ${({ isBurgerOpen }) => (isBurgerOpen ? 'fixed' : 'static')};
	width: 100%;
	padding: 79px 0 0;

	@media (max-width: 767px) {
		padding: 55px 0 0;
	}
`;

export default PaddingTopContainer;
