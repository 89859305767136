import React, { useState } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import { ArticleWithCreators } from 'apiFolder/directus-main';

import Seo from 'components/seo';
import { Content } from 'components/Content';
import Footer from 'components/Footer/Footer';
import Header from 'components/common/header/Header';
import ThemeProviderComponent from 'components/common/ThemeProvider';
// import InfoCard from 'components/InfoCard';
import PaddingTopContainer from 'components/common/header/PaddingTopContainer';
// import TabArticle from 'components/article/TabArticle';
import TabArticleWithCreators from 'components/article/TabArticleWithCreators';

interface IArticleWithCreators {
	pageContext: {
		dataArticles: ArticleWithCreators[];
	};
}

const PatientResources: React.FC<IArticleWithCreators> = ({ pageContext: { dataArticles } }) => {
	const [isMenuOpen, toggleMenu] = useState(false);

	const toggleBurgerMenu = () => {
		toggleMenu(!isMenuOpen);
	};

	return (
		<>
			<Seo title='Patient Resources Centre' description='' />
			<ThemeProviderComponent>
				<Header toggleBurgerMenu={toggleBurgerMenu} isBurgerOpen={isMenuOpen} />
				<PaddingTopContainer isBurgerOpen={isMenuOpen}>
					<TitleBlock>
						<Wrap>
							<Title>Patient Resources Centre</Title>
							<Text>
								Finding pain, injury and condition information is easy. It's everywhere. But finding
								trustworthy, relevant, actionable help can be tricky and sometimes overwhelming.
								BookPhysio.com is changing all that. With the help of our panel of expert contributors,
								medical reviewers and talented editorial team - we're building a comprehensive library
								of content to help you get back to feeling your best - no matter what you're dealing
								with.
							</Text>
							<LinksBlock>
								<Link to={'/editorial-team'}>Editorial Team</Link>
								<Link to={'/editorial-policy'}>Editorial Policy</Link>
								<Link to={'/content-disclaimer'}>Content Disclaimer</Link>
								<Link to={'/advertising-policy'}>{`Ad & Sponsorship Policy`}</Link>
							</LinksBlock>
						</Wrap>
					</TitleBlock>
					<Content isOpen={isMenuOpen}>
						<TextBlock>
							<Cards>
								<TextPar>
									{dataArticles.length ? (
										dataArticles.map((article) => (
											<TabArticleWithCreators
												key={article.articleId}
												img={article.image}
												title={article.heading}
												writtenBy={article.authors}
												lastReviewed={article.lastReviewed}
												link={article.slug}
											/>
										))
									) : (
										<div>Content will be added soon</div>
									)}
								</TextPar>
							</Cards>
						</TextBlock>
					</Content>
					<Footer />
				</PaddingTopContainer>
			</ThemeProviderComponent>
		</>
	);
};
const TitleBlock = styled.div`
	display: relative;
	left: 0;
	width: 100%;
	background-color: #f7f8fa;
	padding: 80px 0;
	border-bottom: 1px solid #bdc5cd;

	@media (max-width: 767px) {
		padding: 40px 0;
	}
`;
const Wrap = styled.div`
	max-width: 1140px;
	width: 100%;
	margin: 0 auto;
	padding: 0 15px;
`;
const Title = styled.h1`
	font-family: 'SharpGrotesk-MediumNo21', sans-serif;
	font-weight: 600;
	line-height: 1.3;
	color: #152231;
	font-size: 30px;

	@media (max-width: 1024px) {
		font-size: 24px;
	}
`;
const Text = styled.p`
	margin: 40px 0;
	font-weight: 500;
	font-size: 18px;
	line-height: 1.5;
`;
const LinksBlock = styled.ul`
	display: flex;
	flex-wrap: wrap;
	& a {
		white-space: nowrap;
		margin: 0 40px 20px 0;
		color: ${({ theme }) => theme.palette.purp};
		transition: all ease 0.3s;

		@media (min-width: 1367px) {
			&:hover {
				text-decoration: none;
				color: ${({ theme }) => theme.palette.grey_text};
			}
		}
		@media (max-width: 767px) {
			flex: 0 0 calc(49% - 25px);
			margin: 0 10px 20px 0;

			&:nth-child(1) {
				order: 1;
			}
			&:nth-child(2) {
				order: 3;
			}
			&:nth-child(3) {
				order: 2;
			}
			&:nth-child(4) {
				order: 4;
			}
		}
	}
`;

const TextBlock = styled.div``;

const TextPar = styled.div`
	transition: all 0.3s ease;

	font-family: 'MuseoSans', sans-serif;
	font-style: normal;
	font-weight: 300;
	font-size: 16px;
	line-height: 23px;
	margin-top: 24px;

	width: 100%;
	display: flex;
	flex-wrap: wrap;
	padding: 60px 0 50px;

	@media (max-width: 1023px) {
		padding: 40px 0 30px;
	}
	@media (max-width: 767px) {
		padding: 30px 0 20px;
	}
`;

const Cards = styled.div`
	margin: 0 -15px;
`;

export default PatientResources;
